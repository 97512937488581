export default {
    path: '/production',
    name: 'production',
    component: () => import('@/layouts/BaseLayout'),
    redirect: '/production/production_create',
    children: [
      {
        path: 'production_create',
        meta: { title: '生产开单', permission: 'production_create' },
        component: () => import('@/views/production/productionCreate/index'),
      },
      {
        path: 'production_record',
        meta: { title: '生产记录', permission: 'production_record' },
        component: () => import('@/views/production/productionRecord/index'),
      },
      {
        path: 'production_record_detail',
        meta: { title: '生产记录详情', permission: 'production_record_detail' },
        component: () => import('@/views/production/productionRecordDetail/index'),
      },
      {
        path: 'production_return_create',
        meta: { title: '生产撤销', permission: 'production_return_create' },
        component: () => import('@/views/production/productionReturnCreate/index'),
      },
      {
        path: 'production_task_process',
        meta: { title: '生产进度', permission: 'production_task_process' },
        component: () => import('@/views/production/productionTask/index'),
      },
      {
        path: 'process',
        meta: { title: '生产流程', permission: 'production_task_process' },
        component: () => import('@/views/production/process/index'),
      },
      {
        path: 'production_task_manage',
        meta: { title: '流程管理', permission: 'production_task_manage' },
        component: () => import('@/views/production/productionTaskManage/index'),
      },
    ],
  }