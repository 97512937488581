export default {
    path: '/demo',
    name: 'demo',
    component: () => import('@/layouts/BaseLayout'),
    children: [
      {
        path: 'demo_1',
        name: 'demo_1',
        meta: { title: 'demo', permission: 'client' },
        component: () => import('@/views/demo/index'),
      },
    ],
  }