export default {
  state: () => ({
    tableFilters: {}
  }),
  mutations: {
    setTableFilters(state, filters) {
      state.tableFilters = filters;
    }
  },
  actions: {
    updateTableFilters({ commit }, filters) {
      commit('setTableFilters', filters);
    }
  }
}