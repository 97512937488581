export default {
  path: '/materials',
  name: 'materials',
  component: () => import('@/layouts/BaseLayout'),
  redirect: '/finance/classification',
  children: [
    {
      path: 'classification',
      meta: { title: '物料分类', permission: 'classification' },
      component: () => import('@/views/materials/classification/index'),
    },
    {
      path: 'attr',
      meta: { title: '物料属性', permission: 'attr' },
      component: () => import('@/views/materials/attr/index'),
    },
    {
      path: 'unit',
      meta: { title: '物料单位', permission: 'unit' },
      component: () => import('@/views/materials/unit/index'),
    },
    {
      path: 'information',
      meta: { title: '物料信息', permission: 'information' },
      component: () => import('@/views/materials/information/index'),
    },
    {
      path: 'production',
      meta: { title: '物料生产', permission: 'production' },
      component: () => import('@/views/materials/production/index'),
    },
    {
      path: 'production_record_detail',
      meta: { title: '物料生产详情', permission: 'production_record_detail' },
      component: () => import('@/views/materials/productionRecordDetail/index'),
    },
    {
      path: 'development',
      meta: { title: '物料开发', permission: 'development' },
      component: () => import('@/views/materials/development/index'),
    },
    {
      path: 'dev_record_detail',
      meta: { title: '物料开发详情', permission: 'dev_record_detail' },
      component: () => import('@/views/materials/developRecordDetail/index'),
    },
  
  ],
}